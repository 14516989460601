import React, { useContext } from 'react'
import { Button } from '@veneer/core'
import './index.scss'
import 'styles/global.scss'
import ConfigContext from 'context/config/ConfigContext'

const TableFooter = (props) => {
  const { disabledAction, onCancelClick, action, isWex } = props
  const { t } = useContext(ConfigContext)
  return (
    <div className={`task-fixed ${isWex ? 'colorHpBgWx' : 'colorHpBg'}`}>
      <div className={'task-buttons-fixed'}>
        <Button
          id={'cancelButton'}
          appearance={'secondary'}
          onClick={onCancelClick}
        >
          {t('common.cancel')}
        </Button>
        <div className={'marginLeft12'}>
          <Button
            id={'actionButton'}
            onClick={action.onClick}
            disabled={disabledAction}
          >
            {t(`task.footer.${action.name}`)}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TableFooter
