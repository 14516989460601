import React from 'react'
import { SideBar } from '@veneer/core'

const GroupsPanel = (props) => (
  <SideBar
    behavior={'persistent'}
    position={'start'}
    collapsable
    show={props.show}
    content={props.children}
    onCollapse={() => props.onChange(false)}
    onExpand={() => props.onChange(true)}
    size={280} //task-group-column
  />
)

export default GroupsPanel
